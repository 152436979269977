import React from 'react'
import EventsList from '../components/EventsList/EventsList'
import { graphql } from 'gatsby'
import HeroImage from '../components/HeroImage/HeroImage'
import SEO from '../components/SEO/SEO'

export default function UpcomingPage({ data }) {
  const {
    markdownRemark: {
      frontmatter: {
        image: {
          childImageSharp: { fluid }
        },
        alt
      }
    }
  } = data
  return (
    <>
      <SEO title="Upcoming" route="/upcoming"></SEO>
      <HeroImage image={fluid} alt={alt} />
      <EventsList />
    </>
  )
}

export const upcomingPageQuery = graphql`
  {
    markdownRemark(fileAbsolutePath: { regex: "/content/pages/upcoming.md/" }) {
      id
      frontmatter {
        image {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        alt
      }
    }
  }
`
