import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Link from '../Link/Link'
import styles from './EventsList.module.scss'
import moment from 'moment'

export default function EventsList() {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "//content/events//" } }
        sort: { order: DESC, fields: frontmatter___date }
      ) {
        edges {
          node {
            id
            frontmatter {
              date
              location
              type
              url
            }
            html
          }
        }
      }
    }
  `)
  const events = data.allMarkdownRemark.edges
  return (
    <>
      {events.length ? (
        <ul className={styles.eventsList}>
          {events.map(e => {
            const id = e.node.id
            const html = e.node.html
            const event = e.node.frontmatter
            const date = event.date
            const dateString = moment(date).format('MMMM Do YYYY, h:mma')

            return (
              <li key={id} className={styles.eventWrapper}>
                <div>
                  <p className={styles.date}>{dateString}</p>
                  <h3 className={styles.location}>{event.location}</h3>
                  <p className={styles.type}>{event.type}</p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: html
                    }}
                    className={styles.markdown}
                  />
                  {event.url ? (
                    <Link to={event.url} className={styles.link}>
                      More
                    </Link>
                  ) : null}
                </div>
              </li>
            )
          })}
        </ul>
      ) : (
        <p className={styles.noEvent}>Next event in preparation</p>
      )}
    </>
  )
}
